const $ = require("jquery");

const alertMessage = type => {
  if (type === "error") {
    let error = '<div class="alert alert-danger" role="alert">' + "Please fill in all the forms" + "</div>";
    $("#alertContainer").fadeIn(200).html(error).fadeOut(3000);
  } else if (type === "success") {
    let success = '<div class="alert alert-success" role="alert">' + "User Added" + "</div>";
    $("#alertContainer").fadeIn(200).html(success).fadeOut(3000);
  }
};
export default alertMessage;
