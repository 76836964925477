const $ = require("jquery");
import createUserCard from "./createUserCard";
$("#users").on("click", e => {
  if (e.target.id === "deleteBtn") {
    function deleteUser(id) {
      let userList = JSON.parse(localStorage.getItem("usersArr"));
      const filtered = userList.filter(user => user.id !== id);
      localStorage.setItem("usersArr", JSON.stringify(filtered));
      createUserCard(JSON.parse(localStorage.getItem("usersArr")));
    }

    deleteUser(e.target.dataset.id);
  }
});
