const $ = require("jquery");

const createUserCard = users => {
  let html = users.map(user => {
    return `
    <div class="col-md-4 d-inline mb-4">
       <div class="card" style="width: 18rem">
         <img src="${user.img}" class="card-img-top" alt="..." />
         <div class="card-body">
           <h5 class="card-title" id="Name">${user.name}</h5>
           <small class="fw-bold" id="email">${user.email}</small>
           <br>
           <small class="fw-bold" id="job">${user.job}</small>
            <p id="desc" class="card-text mt-3">${user.description}</p>
            <button class='btn btn-danger' id='deleteBtn' data-id=${user.id}>Delete User</button>

          </div>
       </div>
      </div>`;
  });
  $("#users").html(html);
  $("#first").val("");
  $("#last").val("");
  $("#email").val("");
  $("#description").val("");
  $("#select").val("Select Title");
};

export default createUserCard;
