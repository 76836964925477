const $ = require("jquery");
import { v4 as uuid } from "uuid";
import createUserCard from "./createUserCard";
import alertMessage from "./alertMessage";
import { sortBy } from "./sortBy";

$(document).ready(() => {
  let ls = JSON.parse(localStorage.getItem("usersArr"));
  if (ls === null) {
    localStorage.setItem("usersArr", []);
  } else {
    createUserCard(ls);
  }
  $("#userForm").on("submit", () => {
    if ($("#first").val().trim() === "" || $("#last").val().trim() === "" || $("#email").val().trim() === "" || $("#description").val().trim() === "" || $("#select").val() === null) {
      alertMessage("error");
    } else {
      let user = {
        id: uuid(),
        img: "https://images.pexels.com/photos/1036857/pexels-photo-1036857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        name: $("#first").val() + " " + $("#last").val(),
        email: $("#email").val(),
        job: $("#select").val(),
        description: $("#description").val()
      };
      let users = [];
      alertMessage("success");
      if (localStorage.getItem("usersArr") === "") {
        users = [];
      } else {
        users = JSON.parse(localStorage.getItem("usersArr"));
      }
      ls = [...users, user];
      localStorage.setItem("usersArr", JSON.stringify(ls));
      createUserCard(ls);
    }
    return false;
  });
  $("#sort").on("change", e => {
    let value = e.target.value;
    switch (value) {
      case "fe":
        sortBy(ls, "Front End");
        break;
      case "be":
        sortBy(ls, "Back End");
        break;
      case "fs":
        sortBy(ls, "Full Stack");
        break;
      default:
        createUserCard(ls);
    }
  });
});
