const $ = require("jquery");
import createUserCard from "./createUserCard";

export const sortBy = (arr, value) => {
  let jobs = arr.map(job => job.job);
  let array = jobs.filter(job => job === value);
  let singleJob = array[0];
  let jobsArr = arr.filter(job => job.job === singleJob);
  createUserCard(jobsArr);
};
